<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="itemMasterValidation">
      <b-form>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="$router.go(-1)"
            >
              <feather-icon icon="SkipBackIcon" /> Back
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Role Key"
                rules="required"
              >
                <b-form-input
                  v-model="form.role_key"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="Role Key"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  v-model="form.role_name"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder="Role Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="text-uppercase text-bold"></div>
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Module</th>
                  <th>Read</th>
                  <th>Write</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(mod, index) in modules_map"
                  :key="'roles_table' + index"
                >
                  <td>{{ mod.module_name }}</td>
                  <td>
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="mod.read" />
                      <i class="form-icon"></i>
                    </label>
                  </td>
                  <td>
                    <label class="form-checkbox">
                      <input type="checkbox" v-model="mod.write" />
                      <i class="form-icon"></i>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>

          <b-col cols="12" class="text-center">
            <b-button variant="primary" type="submit" @click.prevent="saveRole">
              Save
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
  BTable,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import { BFormSelect } from "bootstrap-vue";
import UserServices from "@/apiServices/UserServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  name: "IngredientForm",
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      item_id: null,
      form: {
        role_id: null,
        role_key: null,
        role_name: null,
      },
      role_modules_map: [],
      required,
    };
  },
  computed: {
    modules_map: function () {
      const sys_modules = this.$store.getters["user/getSysModules"];
      const arr = [];
      for (let i = 0; i < sys_modules.length; i++) {
        const module = sys_modules[i];

        const role_modules_map = this.role_modules_map.find(
          (item) => item.mrm_module_relation.module_id === module.module_id
        );

        arr.push({
          module_id: module.module_id,
          module_name: module.module_name,
          read:
            role_modules_map &&
            (role_modules_map.mrm_access === "READ" ||
              role_modules_map.mrm_access === "WRITE"),
          write: role_modules_map && role_modules_map.mrm_access === "WRITE",
        });
      }
      return arr;
    },
  },
  methods: {
    async saveRole() {
      try {
        const response = await UserServices.saveRole({
          ...this.form,
          modules_map: this.modules_map,
        });
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Saved Succesfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$router.go(-1);
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Cannot Save Role",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.log("Error in saveRole", err);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }
    },
    async getRole(role_id) {
      try {
        const response = await UserServices.getSingleRole({ role_id });
        if (response.data.status) {
          this.form = response.data.data.role;
          this.role_modules_map = response.data.data.role_modules_map;
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
  },
  beforeMount() {
    this.$store.dispatch("user/setSysModules");
    if (this.$route.params.role_id && !isNaN(this.$route.params.role_id)) {
      this.form.role_id = this.$route.params.role_id;
      this.getRole(this.form.role_id);
    }
  },
};
</script>

<style></style>
